import React from "react";
import "./topbar.css";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";
import mass_2 from '../../assets/mass_2.png';

export default function Topbar() {

  const handleButtonClick = () => {
    window.location.href = 'https://masadmin.masclinicas.com/';
  };

  return (
    <div className="topbar">
      <div className="topbarWrapper">
     
        <div className="topLeft">
          {/* <span className="logo">lamaadmin</span> */}
        </div>

        <div className="topRight">
          {/* <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
          </div> */}
          <div onClick={handleButtonClick} className="butunforgomas">
            {/* <img src={mass_2}></img> */}
            <h5>Mas Clinicas</h5>
          </div>
          <img src="https://images.pexels.com/photos/1526814/pexels-photo-1526814.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" className="topAvatar" />
        </div>
        
      </div>
    </div>
  );
}
