import React, { useState, useEffect, } from 'react'
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
import './Doctor_Details.css';
import GoogleMapReact from 'google-map-react';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import ReactStars from "react-rating-stars-component";

export default function Doctor_Details() {
    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);
    const [text3, setText3] = React.useState(false);
    const [text4, setText4] = React.useState(false);
    const [text5, setText5] = React.useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalapproved, setModalapproved] = useState(false);
    const [modal_disapproved, setModal_disapproved] = useState(false);
    const [modalforfavorite, setModalforfavorite] = useState(false);
    const [modalremovefavorite, setModalremovefavorite] = useState(false);

    const [name, setName] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [consult_now, setConsult_now] = React.useState("");

    const [mobile_number, setMobile_number] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [city, setCity] = React.useState("");

    const [gender, setGender] = React.useState("");
    const [favourite, setFavourite] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [degree, setDegree] = React.useState("");
    const [c_name, setC_name] = React.useState("");
    const [license_number, setLicense_number] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [license_image, setIicense_image] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [status, setStatus] = React.useState("");

    const [just_Show, setJust_Show] = useState(null);

    const [patLoading, setPatLoading] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [user_profile, setUser_profile] = useState(null);

    const params = useParams();
    const navigate = useNavigate();

    function fileSelectedHandler(event) {
        const file = event.target.files[0];
        // setFile(event.target.files[0]);
        const reader = new FileReader();
        reader.onload = function (event) {
            const imageUrl = event.target.result;
            setJust_Show(imageUrl);
        };
        reader.readAsDataURL(file);
        console.log("file", file)
        setTimeout(() => {
            update_doc_pic(file)
        }, 100);
    }

    // Change Btn
    const openText1 = (event) => {
        event.preventDefault();
        setText1(true);
        setText2(false);
        setText3(false);
        setText4(false);
        setText5(false);
    };
    const openText2 = (event) => {
        event.preventDefault();
        setText2(true);
        setText1(false);
        setText3(false);
        setText4(false);
        setText5(false);
    };
    const openText3 = (event) => {
        event.preventDefault();
        setText3(true);
        setText1(false);
        setText2(false);
        setText4(false);
        setText5(false);
    };
    const openText4 = (event) => {
        event.preventDefault();
        setText4(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText5(false);
    };
    const openText5 = (event) => {
        event.preventDefault();
        setText5(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText4(false);
    };



    useEffect(() => {
        getProducts()
        getReview()
    }, [])
    const getProducts = async () => {
        let result = await fetch(Connection + `one_doctor/${params.id}`, {

        })
        result = await result.json()
        setName(result[0].name)
        setCategory(result[0].category)
        setConsult_now(result[0].consult_now)

        setMobile_number(result[0].mobile_number)
        setEmail(result[0].email)
        setAddress(result[0].address)
        setCity(result[0].city)
        setGender(result[0].gender)
        setFavourite(result[0].favourite)
        setExperience(result[0].experience)
        setDegree(result[0].degree)
        setC_name(result[0].c_name)
        setLicense_number(result[0].license_number)
        setProfile(result[0].profile)
        setIicense_image(result[0].license_image)
        setFee(result[0].fee)
        setStatus(result[0].status)
        console.warn(name)

    }


    const getReview = async () => {
        let result = await fetch(Connection + `getReview_bydoctor/${params.id}`, {

        })
        result = await result.json()
        setUser_profile(result[0].user_profile)
        setReviews(result)
    }



    const getAddress = (event) => {
        event.preventDefault();
        // if (!name || !email || !mobile_number || !fee || !address || !gender || !city || !degree || !experience || !category || !consult_now || !c_name) {
        //    alert('Information is not complete');
        //    return false
        // }
        // else {
        setPatLoading(true);

        let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAr6YRTjfl2eKXIJZd97_uw9KpStXFRiCE`;
        console.log("pass => ", api);

        fetch(api, {
            method: "POST",
        })
            .then((response) => response.json())
            .then((response) => {
                console.log("pass => ", response);
                let result = response["results"];
                if (result == "") {
                    setPatLoading(false);
                    alert("Invalid Address.")
                }
                else {
                    //getting lat lng from user address
                    let all = result[0].geometry;
                    let location = all.location;
                    let lat = location["lat"];
                    let lng = location["lng"];

                    console.log("lat => ", lat);
                    console.log("lng => ", lng);
                    // setLat(lat);
                    // setLng(lng);

                    Update_doctor(lat, lng)
                }
            })
            .catch((error) => {
                setPatLoading(false);
                alert("Something went wrong.")
                console.error(error);
            });
        // }
    };


    const Update_doctor = async (lat, lng) => {
        console.warn(name, email, mobile_number, fee, address, city, category, consult_now)

        let result = await fetch(Connection + `update_doctor/${params.id}`, {
            method: 'post',
            body: JSON.stringify({ name, email, mobile_number, fee, address, lat, lng, gender, city, degree, experience, category, consult_now, c_name }),
            headers: {
                "Content-Type": 'application/json'
            }
        })

        result = await result.json()
        console.log("result123", result)
        console.log("result.auth", result.auth)

        if (result) {
            getProducts()
            setPatLoading(false);
            //   navigate('/')
        }
    }


    const update_doc_pic = async (file) => {
        // event.preventDefault();

        console.log("connect_file1", file)

        setPatLoading(true);

        const form_data = new FormData();

        form_data.append("doctor", file);

        const api = Connection + `update_doc_pic/${params.id}`
        const result = await fetch(api, {
            method: "POST",
            body: form_data,
        })
            .then((result) => result.json())
            .then((result) => {
                console.log("result", result);
                getProducts()
                setPatLoading(false);
            })

            .catch((error) => {
                console.error(error);
                setPatLoading(false);
                alert("Something went wrong")
            });

        console.log("result123", result)
    }


    const Delete_patient = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `delete_patient/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalDelete(false)
            navigate('/Doctor_list')
        }
    }


    const Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `approve_doctor/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalapproved(false)
            navigate('/Pending_doctor')
        }
    }


    const Dis_Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `disapprove_doctor/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModal_disapproved(false)
            navigate('/Doctor_list')
        }
    }


    const addasfavort = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `addas_favorite/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalforfavorite(false)
            navigate('/Doctor_list')
        }
    }

    const removefromfavort = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `remove_favorite/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalremovefavorite(false)
            navigate('/Doctor_list')
        }
    }


    return (
        <div className='Doctor_head'>
            <h2>DOCTOR PROFILE</h2>

            <div className='All_in_this'>

                <div className='first_column'>
                    <div className='Profile_card'>
                        <div className='img-wrapper'>
                            {profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                :
                                <div className='profileorbtn'>
                                    {just_Show == null ?
                                        <img src={Image_connection + profile} title="Profile Image" alt="" />
                                        :
                                        <img src={just_Show} alt="" className="fab fa-firstdraft" />
                                    }
                                </div>
                            }
                            <div className="custom_prof_btn">
                                <input type="file" onChange={fileSelectedHandler} />
                                <label style={{ fontSize: 13 }}>Update Profile</label>
                            </div>
                        </div>

                        <div className='profile_detail'>
                            <h2>{name}</h2>
                            <div className='star'>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <span>(38)</span>
                            </div>
                            <h3>{category}</h3>

                            <div className='just_row'>
                                <i class="bi bi-telephone"></i>
                                <h4>{mobile_number}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-envelope-at"></i>
                                <h4>{email}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-currency-dollar"></i>
                                <h4>{fee}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='chanebtn_card'>
                        <div className={text1 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText1}>
                            <a href='#'>About Me</a>
                        </div>

                        <div className={text2 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText2}>
                            <a href='#'>Reviews</a>
                        </div>

                        <div className={text3 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText3}>
                            <a href='#'>Approved</a>
                        </div>

                        <div className={text4 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText4}>
                            <a href='#'>Edit</a>
                        </div>

                        <div className={text5 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText5}>
                            <a href='#'>Delete</a>
                        </div>
                    </div>


                    {text1 == true ?
                        <div className='About_in_btn'>
                            <h2>ABOUT</h2>
                            <hr></hr>

                            <div className='numer_licen'>
                                <h3>License Number</h3>
                                <h4>{license_number}</h4>
                                <hr></hr>
                            </div>

                            <div className='img_licen'>
                                <h3>License Image</h3>
                                {license_image == null ?
                                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                    :
                                    <img src={Image_connection + license_image} title="license Image" alt="" />
                                }
                            </div>
                        </div>
                        :
                        <div></div>
                    }


                    {text2 == true ?
                        <div className='Review_Card'>
                            <h2>Recent Review</h2>

                            {reviews == "" ?
                                <div className='Noreview'>
                                    <h4>No Review Available</h4>
                                </div>
                                :
                                <>
                                    {reviews.map((row) => (
                                        <div className='review_all'>
                                            {user_profile == null ?
                                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                                :
                                                <img src={Image_connection + user_profile} title="license Image" alt="" />
                                            }

                                            <div className='review_details'>
                                                <h3>{row.user_name}</h3>

                                                <p>{row.comment}</p>

                                                <div className='bottom_deatil_review'>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>({row.rating})</h3>
                                                        <ReactStars
                                                            count={5}
                                                            value={row.rating}
                                                            size={24}
                                                            edit={false}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                        :
                        <div></div>
                    }



                    {text3 == true ?
                        <div className='Approv_Diss_Card'>
                            <h2>For Approval</h2>
                            <hr></hr>

                            <div style={{ display: 'flex', alignSelf: 'center', gap: 20, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <span className='two_buttun'>
                                    {status == 'approved' ?
                                        <button onClick={() => { setModal_disapproved(true) }}>Disapproved</button>
                                        :
                                        <button onClick={() => { setModalapproved(true) }}>Approved</button>
                                    }
                                </span>

                                <span className='two_buttun'>
                                    {favourite == 'favourite' ?
                                        <button onClick={() => { setModalremovefavorite(true) }}>Unfavourite</button>
                                        :
                                        <button onClick={() => { setModalforfavorite(true) }}>Favourite</button>
                                    }
                                </span>
                            </div>
                        </div>
                        :
                        <div></div>
                    }


                    {text4 == true ?
                        <form className='Update_seting_form'>
                            <h2>SETTING</h2>
                            <hr></hr>

                            <div class="updat_rst_name">
                                <div className='updt_width_both'>
                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Name</label>
                                        </div>
                                        <input type="text" class="" id="name" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Email</label>
                                        </div>
                                        <input type="email" class="" id="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>



                            <div class="updat_rst_name">
                                <div className='updt_width_both'>
                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Mobile Number</label>
                                        </div>
                                        <input type="text" class="" id="email" placeholder="Mobile Number" value={mobile_number} onChange={(e) => setMobile_number(e.target.value)} />
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Fee</label>
                                        </div>
                                        <input type="number" class="" id="email" placeholder="Your Fee" value={fee} onChange={(e) => setFee(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            {/* <div class="updt_seond_subject">
                                <div className='updt_width_suject'>
                                    <div className='updatsubject_name'>
                                        <label>Address</label>
                                    </div>
                                    <input type="text" class="subject" id="subject" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div> */}


                            <div className="first-name">
                                <div className='width-both-name-email'>
                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>Gender</label>
                                        </div>
                                        <select id="email" pla value={gender} onChange={(e) => setGender(e.target.value)}>
                                            <option>{gender}</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>Country</label>
                                        </div>
                                        <select id="subject" value={city} onChange={(e) => setCity(e.target.value)}>
                                            <option>{city}</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="Maxico">Maxico</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="first-name">
                                <div className='width-both-name-email'>
                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>Degree</label>
                                        </div>
                                        <input type="text" className="" id="email" placeholder="Degree" value={degree} onChange={(e) => setDegree(e.target.value)} />
                                    </div>

                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>Experience Years (Only Digits)</label>
                                        </div>
                                        <input type="number" className="" id="email" placeholder="Experience" value={experience} onChange={(e) => setExperience(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div class="updat_rst_name">
                                <div className='updt_width_both'>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Choose Category</label>
                                        </div>
                                        <select id="name" value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option >{category}</option>
                                            <option value="General Clinic">General Clinic</option>
                                            <option value="Dentists">Dentists</option>
                                            <option value="Cardialogy">Cardialogy</option>
                                            <option value="Pediatric">Pediatric</option>
                                            <option value="Dermatology">Dermatology</option>
                                            <option value="Orthopedician">Orthopedician</option>
                                            <option value="Neurologists">Neurologists</option>
                                            <option value="Mental Health">Mental Health</option>
                                            <option value="Therapist">Therapist</option>
                                        </select>
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Consult Now</label>
                                        </div>
                                        <select id="name" value={consult_now} onChange={(e) => setConsult_now(e.target.value)}>
                                            <option>{consult_now}</option>
                                            <option value="On">On</option>
                                            <option value="Off">Off</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="second-subject">
                                <div className='width-subject'>
                                    <div className='subject-name'>
                                        <label>About Clinic (70 words required)</label>
                                    </div>
                                    <textarea className="message" placeholder="Write about your Clinic" value={c_name} onChange={(e) => setC_name(e.target.value)} id="message" ></textarea>
                                </div>
                            </div> */}



                            <div className="first-name">
                                <div className='width-both-name-email'>
                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>Address</label>
                                        </div>
                                        <input type="text" className="" id="email" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </div>

                                    <div className='width-name'>
                                        <div className='subject-name'>
                                            <label>About Clinic (70 words required)</label>
                                        </div>
                                        <input type="text" className="" id="email" placeholder="Write about your Clinic" value={c_name} onChange={(e) => setC_name(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            <div class="updt_buttun_center">
                                <button class="submit" id='reset' type="reset">Reset</button>
                                {patLoading ?
                                    <button className='submit' id='delete_loader' href="">
                                        <PulseLoader
                                            color={"white"}
                                            loading={patLoading}
                                            //  size={5}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </button>
                                    :
                                    <button onClick={getAddress} class="submit" id='submite'>Submit</button>
                                }
                            </div>
                        </form>
                        :
                        <div></div>
                    }


                    {text5 == true ?
                        <div className='Delete_Card'>
                            <h2>Delete Account</h2>
                            <hr></hr>

                            <h4>Are you sure, You want to delete this doctor?</h4>

                            <span className='delete_buttun'>
                                <button onClick={() => { setModalDelete(true) }}>Delete</button>
                            </span>
                        </div>
                        :
                        <div></div>
                    }
                </div>

                <div className='second_column'>
                    <div className='About_card'>
                        <div className='about_head'>
                            <h2>About Me</h2>
                        </div>

                        <hr></hr>

                        <div className='about_data'>
                            <p>{c_name}</p>
                            <hr></hr>
                            <div className='about_dat_row'>
                                <h3>Gender</h3>
                                <h4>{gender}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Experience</h3>
                                <h4>{experience} years</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Degree</h3>
                                <h4>{degree}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Designation</h3>
                                <h4>J.Professer</h4>
                            </div>

                            <hr></hr>

                            <div className='about_last_row'>
                                <div className='about_last_section'>
                                    <h3>37</h3>
                                    <h4>PATIENT</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>61</h3>
                                    <h4>REVIEW</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>{fee}</h3>
                                    <h4>FEE</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Address_Card'>
                        <div className='address_head'>
                            <h2>Address</h2>
                        </div>

                        <hr></hr>

                        <div className='address_paragraph'>
                            <p>{address}</p>
                        </div>
                    </div>
                </div>

            </div>


            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE DOCTOR
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_patient}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modalapproved} toggle={() => setModalapproved(!modalapproved)}>
                <ModalHeader toggle={() => setModalapproved(!modalapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to approved this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Approved_doctor}>Approved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modal_disapproved} toggle={() => setModal_disapproved(!modal_disapproved)}>
                <ModalHeader toggle={() => setModal_disapproved(!modal_disapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to disapproved this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Dis_Approved_doctor}>Disapproved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            {/* Modal for doctor add to favourite and unfavourite */}
            <Modal size='lg' isOpen={modalforfavorite} toggle={() => setModalforfavorite(!modalforfavorite)}>
                <ModalHeader toggle={() => setModalforfavorite(!modalforfavorite)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to add favourite this doctor?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={addasfavort}>Favourite</button>
                        }
                    </div>
                </ModalBody>
            </Modal>


            <Modal size='lg' isOpen={modalremovefavorite} toggle={() => setModalremovefavorite(!modalremovefavorite)}>
                <ModalHeader toggle={() => setModalremovefavorite(!modalremovefavorite)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to remove this doctor from favourite?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={removefromfavort}>Unfavourite</button>
                        }
                    </div>
                </ModalBody>
            </Modal>
            {/*  */}

        </div>
    );
}