import "./sidebar.css";
import React, {useState, useEffect, useMemo, useContext } from 'react'
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import { Link,useNavigate } from "react-router-dom";
import {GlobalInfo} from "../../App"

export default function Sidebar() {
   const { loginenter } = useContext(GlobalInfo)

   const navigat=useNavigate()
   
   const logout = async (e) => {
      console.log("logout")
      localStorage.clear();
      loginenter()
      // window.location.href = '/'
   }

   // useEffect(() => {
   //    const timer = setTimeout(() => {
   //         logout()
   //         loginenter()
   //    }, 3000);
      
   //    // Clear the timer when the component unmounts or the user manually logs out
   //    return () => {
   //      clearTimeout(timer);
   //    }
   //  }, [logout]);

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">

         <ul className="sidebarList">
            <Link className="link">
               <li className="logotop">Mas Clinicas Latam</li>
            </Link>
         </ul>

        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Dashboard</h3>
        
           <ul className="sidebarList">
               <Link to="/" className="link">
                   <li className="sidebarListItem active"><LineStyle className="sidebarIcon" />Dashboard</li>
               </Link>

               <Link to="/Fucture_update" className="link">
                   <li className="sidebarListItem"><Timeline className="sidebarIcon" />Fucture Update</li>
               </Link>
           </ul>
        </div>


        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Patient</h3>
        
           <ul className="sidebarList">
               <Link to="/Patient_list" className="link">
                   <li className="sidebarListItem active"><LineStyle className="sidebarIcon" />Patient List</li>
               </Link>
          
               <Link to="/add_patient" className="link">
                   <li className="sidebarListItem"><Timeline className="sidebarIcon" />Add Patient</li>
               </Link>

               {/* <li className="sidebarListItem"><TrendingUp className="sidebarIcon" />Sales</li> */}
           </ul>
        </div>
    

        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Doctor</h3>
        
           <ul className="sidebarList">
             <Link to="/Doctor_list" className="link">
                <li className="sidebarListItem"><PermIdentity className="sidebarIcon" />Doctor List</li>
             </Link>
           
             <Link to="/add_doctor" className="link">
                <li className="sidebarListItem"><Storefront className="sidebarIcon" />Add Doctor</li>
             </Link>
         
             <Link to="/Pending_doctor" className="link">
                <li className="sidebarListItem"><AttachMoney className="sidebarIcon" />Pending Doctor</li>
             </Link>
           </ul>
        </div>


        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Appointment</h3>
        
           <ul className="sidebarList">
               <Link to="/Appointment" className="link">
                   <li className="sidebarListItem active"><LineStyle className="sidebarIcon" />Appointment</li>
               </Link>
           </ul>
        </div>
        

        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Wallet</h3>

           <ul className="sidebarList">
             <Link to="/Add_funds" className="link">
                <li className="sidebarListItem"><PermIdentity className="sidebarIcon" />Add funds</li>
             </Link>

             <Link to="/Pending_payout" className="link">
                <li className="sidebarListItem"><PermIdentity className="sidebarIcon" />Pending Payout</li>
             </Link>

             <Link to="/refund" className="link">
                <li className="sidebarListItem"><Storefront className="sidebarIcon" />Refund</li>
             </Link>

             <Link to="/Transactions" className="link">
                <li className="sidebarListItem"><AttachMoney className="sidebarIcon" />Transactions</li>
             </Link>
           </ul>
        </div>

        <div className="sidebarMenu">
           <h3 className="sidebarTitle">Log Out</h3>
        
           <ul className="sidebarList">
               <Link onClick={logout} className="link">
                   <li className="sidebarListItem active"><LineStyle className="sidebarIcon" />logout</li>
               </Link>
           </ul>
        </div>
    
      </div>
    </div>
  );
}
