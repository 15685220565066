import React, { useState, useEffect, useRef } from 'react'
import './Add_patient.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from "axios"
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import moment from 'moment';

export default function Add_patient() {

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [mobile_number, setMobile_number] = useState("");
   const [address, setAddress] = useState("");
   const [city, setCity] = useState("Country");
   const [gender, setGender] = useState("Gender");


   const [modalsuccess, setModalsuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [file, setFile] = useState(null);
   const [fileName, setFileName] = useState(null);
   // const [fileInput, setFileInput] = useState("");
   const fileInput = useRef();

   const [error, setError] = useState(false);


   const navigat = useNavigate()

   const getAddress = (event) => {
      event.preventDefault();

      if (!name || !email || !password || !mobile_number || !address) {
         setError(true)
         alert('Information is not complete');
         return false
      }
      else if (gender == 'Gender') {
         alert('Please select gender');
         return false
      }
      else if (city == 'Country') {
         alert('Please select Country');
         return false
      }
      else {
         setIsLoading(true);

         let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAr6YRTjfl2eKXIJZd97_uw9KpStXFRiCE`;
         console.log("pass => ", api);

         fetch(api, {
            method: "POST",
            // headers: {
            //    "Content-Type": "multipart/form-data",
            //    otherHeader: "foo",
            // },
         })
            .then((response) => response.json())
            .then((response) => {
               console.log("pass => ", response);
               let result = response["results"];
               if (result == "") {
                  setIsLoading(false);
                  alert("Invalid Address.")
               }
               else {
                  //getting lat lng from user address
                  let all = result[0].geometry;
                  let location = all.location;
                  let lat = location["lat"];
                  let lng = location["lng"];

                  console.log("lat => ", lat);
                  console.log("lng => ", lng);
                  // setLat(lat);
                  // setLng(lng);

                  Add_Patient(lat, lng)
               }
            })
            .catch((error) => {
               setIsLoading(false);
               alert("Something went wrong.")
               console.error(error);
            });
      }
   };

   const Add_Patient = async (lat, lng) => {
      var length = 8,
         charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
         r_code = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
         r_code += charset.charAt(Math.floor(Math.random() * n));
      }

      const role = "user"
      const status = "approved"
      const user_date = moment(new Date()).format("YYYY-MM-DD hh:mm A");

      console.log(name, email, password, lat, lng, mobile_number, address, city)

      let result = await fetch(Connection + "add_Patient", {
         method: 'post',
         body: JSON.stringify({ name, email, password, mobile_number, address, lat, lng, gender, city, r_code, role, status, user_date }),
         headers: {
            "Content-Type": 'application/json'
         }
      })
      result = await result.json()
      console.log("result123", result)
      console.log("result.auth", result.auth)

      if (result) {
         setIsLoading(false);
         setModalsuccess(true)
      }
   }

   const Succeess = async (event) => {
      setModalsuccess(false)
      navigat('/Patient_list')
   }

   // const fileuad = (event) => {
   //    event.preventDefault();

   //    // localStorage.clear();
   //    // navigat('/')

   //    const fd = new FormData;
   //    fd.append('image', selectedFile, selectedFile.name)
   //    axios.post('https://netbeans.tech/telehconsult/Chat_pdf', fd, {
   //       onUploadProgress: progressEvent => {
   //          console.log('Upload progress:' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%')
   //       }
   //    })
   //      .then(res => {
   //           console.log(res)
   //      });
   // }

   // const fileupload = (event) => {
   //    event.preventDefault();

   //    const fd = new FormData;

   //    fd.append('image', selectedFile, selectedFile.name)
   //    // fd.append('picture',selectedFile.current.files[0])
   //    var xhr = new XMLHttpRequest();
   //    xhr.open('POST',Connection + 'upload',true)

   //    xhr.send(FormData)
   // }


   const fileSelectedHandler = (e) => {
      // console.log("sdkslkdlk",event.target.files[0]);
      // console.log("sdkslkdlksddvfvf",event.target.files[0].name);

      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      console.log("sdkslkdlksddvfvf", e.target.files[0]);

   }


   const uploadFilek = async (event) => {
      event.preventDefault();
      console.log("file1", file)
      console.log("fileName1", fileName)
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      try {
         const res = await axios.post(
            Connection + "upload_img",
            formData
         );
         console.log(res);
      } catch (ex) {
         console.log(ex);
      }
   };



   const submit = async (event) => {
      event.preventDefault();

      const formdata = new FormData();

      formdata.append("file", file);

      formdata.append("fileName", fileName);


      axios.post(Connection + "upload_img", formdata)
         .then(res => { // then print response status
            console.warn(res);
            //   if(res.data.success === 1){
            //     setSuccess("Image upload successfully");
            //   }

         })
   }




   const connect = async (event) => {

      event.preventDefault();
      console.log("connect_file1", file)
      console.log("connect_fileName1", fileName)

      const form_data = new FormData();
      form_data.append("profg", file);

      const api = Connection + "upload_img"
      const result = await fetch(api, {
         method: "POST",
         body: form_data

      });

      // result=await result.json()
      console.log("result123", result)
   }


   //    const Add_picture =async(event)=>{
   //       event.preventDefault();

   //       let uploaddata = new FormData();

   //       console.log("file",file)
   //       console.log("fileName","profg")

   //       uploaddata.append('file', file);
   //       uploaddata.append('fileName', "profg");

   //       // uploaddata.append('fileName1', fileName);

   //       let result =await fetch(Connection + "upload_img",{
   //           method:'post',
   //           body: uploaddata,
   //           headers:{
   //              "Content-Type":"multipart/form-data"
   //           }
   //       })
   //       result=await result.json()
   //       console.log("result123",result)
   //   }


   const uploadFile = async (e) => {
      console.log("ssss", file)
      console.log("ssss", fileName)

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      try {
         const res = await axios.post(
            Connection + 'upload_img',
            formData
         );
         console.log(res);
      } catch (ex) {
         console.log(ex);
      }
   };

   return (
      <div className='firstinpu'>

         <form className='Form-all-view'>
            <h2>ADD PATIENT</h2>
            <hr></hr>

            {/* <div className='profileImage'>
                   <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                   <a href='' className='prfilebtn'>Add Profile</a>
                      <input  type='file' onChange={fileSelectedHandler} ref={fileInput}/>
                      <button onClick={() => fileInput.click()}>File Picker</button>
                      <Link onClick={connect}>Upload</Link>
               </div> */}

            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Name</label>
                     </div>
                     <input type="text" class="" id="name" placeholder="Full Name" onChange={(e) => setName(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Email</label>
                     </div>
                     <input type="email" className="" id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                  </div>
               </div>
            </div>

            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Address</label>
                  </div>
                  <input type="text" className="subject" id="subject" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
               </div>
            </div>

            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Mobile Number</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="Mobile Number" onChange={(e) => setMobile_number(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Password</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                  </div>
               </div>
            </div>


            {/* <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Country</label>
                  </div>
                  <input type="text" className="subject" id="subject" placeholder="Country" onChange={(e) => setCity(e.target.value)} />
               </div>
            </div> */}



            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Gender</label>
                     </div>
                     <select id="email" pla value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option>{gender}</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                     </select>
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Country</label>
                     </div>
                     <select id="subject" value={city} onChange={(e) => setCity(e.target.value)}>
                        <option>{city}</option>
                        <option value="Peru">Peru</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Maxico">Maxico</option>
                     </select>
                  </div>
               </div>
            </div>

            {/* <div className="second-subject">
                  <div className='width-subject'>
                     <div className='subject-name'>
                        <label>Message</label>
                     </div>
                     <textarea className="message" placeholder="Leave a message here" id="message" ></textarea>
                  </div>
               </div> */}

            <div className="patient_buttun">
               <button className="submit" id='reset' type="reset">Reset</button>
               {isLoading ?
                  <button className="submit" id='loader' type="reset">
                     <PulseLoader
                        color={"white"}
                        loading={isLoading}
                        //  size={5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </button>
                  :
                  <button onClick={getAddress} className="submit" id='submite' type="submit">Submit</button>
               }
            </div>
         </form>

         <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
            <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
               Successfully
            </ModalHeader>

            <ModalBody>
               <div className='succeess_modal'>
                  <h4>You have successfully Add Patient</h4>
                  <button onClick={Succeess}>Done</button>
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}